@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    height: 90%;
  }
  #root {
    height: 100%;
  }
  body {
    color: white;

    height: 100%;
    background-color: rgba(15, 23, 42, 1);
  }
  input,
  textarea {
    @apply p-4;
    @apply text-xl;
    @apply bg-navyDark;
    @apply rounded-xl;
    @apply border;
  }

  input:hover,
  textarea:hover {
    @apply bg-navyLight;
  }
  input:focus-visible,
  textarea:focus-visible {
    @apply bg-navyLight;
    @apply border-yellow;
  }
  input:focus,
  textarea:focus {
    @apply outline;
    @apply outline-0;
    @apply bg-navyLight;
    @apply border;
    @apply border-yellow;
  }

  h1 {
    @apply text-5xl;
    @apply font-bold;
  }

  h2 {
    @apply font-medium;
    @apply text-3xl;
    @apply p-4;
  }
  button {
    @apply text-xl;
    @apply p-4;
    @apply text-center;
    @apply rounded-3xl;
    @apply bg-purple;
    @apply font-bold;
    @apply uppercase;
    color: white;
  }

  select {
    @apply text-xl;
    @apply p-4;
    @apply text-center;
    @apply rounded-3xl;
    @apply bg-purple;
    @apply font-bold;
    @apply uppercase;
    color: white;
    border: 0px;
  }
  .voterShadow {
    text-shadow: -3px 2px 2px #6e3bc6;
  }
  .votingCampaignName {
    text-shadow: 2px 2px 1px #09080826;
  }
  .ripple-button {
    color: #fff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .ripple-button > .ripple {
    width: 20px;
    height: 20px;
    position: absolute;
    background: #63a4ff;
    display: block;
    content: "";
    border-radius: 9999px;
    opacity: 1;
    animation: 0.9s ease 1 forwards ripple-effect;
  }

  @keyframes ripple-effect {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(10);
      opacity: 0.375;
    }
    100% {
      transform: scale(35);
      opacity: 0;
    }
  }

  .ripple-button > .content {
    position: relative;
    z-index: 2;
  }
}
